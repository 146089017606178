import styled from 'styled-components';
import colors from '../../../styles/sass/_variables.scss';
export const StyledNav = styled.nav`
  margin-left: auto;
  width: 100%;

  div {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  span {
    color: ${colors.primaryColor};
    margin: 0 15px;
  }

  .letter-spacing-2 {
    letter-spacing: 2px;
  }
`;
