import React from 'react';
import {
  Container,
  Content,
  Description,
  Height,
  ContactsContainer,
  Text,
  TextWhats,
  Title,
  WhatsLink,
  Icon,
  Subtitle,
  ImageContainer,
} from './style';
import { FaInstagram, FaPhone } from 'react-icons/fa';
import colors from '../../styles/sass/_variables.scss';
import Contact from '../SectionContacts/Contact';
import { Background } from '../../assets/globalStyles/styles';

const SectionHome = () => {
  return (
    <Background
      backgroundcolor={colors.tertiaryTextColor}
      className="flex justify-content-center"
    >
      <ImageContainer aria-label="Psicóloga mais distante, com um leve sorriso">
        <Container>
          <Height aria-label="Psicóloga mais distante, com um leve sorriso">
            <ContactsContainer>
              <Contact
                Icon={FaInstagram}
                text="@psicologagraciellen"
                href="https://instagram.com/psicologagraciellen"
                color={colors.primaryTextColor}
              />
              <Contact
                Icon={FaPhone}
                text="(48) 99209-8484"
                href="tel:48992098484"
                color={colors.primaryTextColor}
              />
            </ContactsContainer>

            <Content>
              <Description>
                <Subtitle>TUDO TEM CURA.</Subtitle>
                <Title>
                  Com a Nova Medicina Germânica encontramos a causa do seu
                  problema de saúde e a solução.
                </Title>
                <TextWhats>
                  <WhatsLink
                    href="https://wa.me/5548992098484"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon size={32} color="25d366" />
                    Saiba mais!
                  </WhatsLink>
                  <Text>Atendimento on-line.</Text>
                </TextWhats>
              </Description>
            </Content>
          </Height>
        </Container>
      </ImageContainer>
    </Background>
  );
};

export default SectionHome;
