import React from 'react';
import {
  Container,
  Section,
  SectionContact,
  SectionFooter,
  SectionH,
  SectionService,
} from './style';
import Header from './Header';
import SectionHome from './SectionHome';
import SectionAboutMe from './SectionAboutMe';
import SectionServices from './SectionServices';
import SectionContacts from './SectionContacts';
import Footer from './Footer';
import SectionAboutNMG from './SectionAboutNMG';
import SectionHowNMG from './SectionHowNMG';

const Main = () => {
  return (
    <Container>
      <Header />
      <SectionH>
        <SectionHome />
      </SectionH>
      <Section>
        <SectionAboutNMG />
      </Section>
      <Section>
        <SectionHowNMG />
      </Section>
      <Section>
        <SectionAboutMe id="sobre" />
      </Section>
      <SectionService id="serviços">
        <SectionServices />
      </SectionService>
      <SectionContact>
        <SectionContacts id="contatos" />
      </SectionContact>
      <SectionFooter>
        <Footer />
      </SectionFooter>
    </Container>
  );
};

export default Main;
