import styled from 'styled-components';
import colors from '../../styles/sass/_variables.scss';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  max-width: 480px;
  min-width: 300px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: 750px;
  }
  @media (min-width: 992px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 48px;
  padding: 10px;
  font-weight: normal;
  font-family: 'Cormorant Garamond', 'serif';

  color: ${colors.backgroundBlack};

  @media (max-width: 970px) {
    font-size: 35px;
  }
`;
export const Description = styled.p`
  color: ${colors.backgroundBlack};
  font-size: 22px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Image = styled.img`
  width: 100%;
  display: flex;
  height: auto;
  justify-content: center;
  max-width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
