import React from 'react';
import { Container, TextContainer } from './style';
import { useMediaQuery } from 'react-responsive';
import {
  Background,
  Content,
  GlobalImageContainer,
} from '../../assets/globalStyles/styles';
import colors from '../../styles/sass/_variables.scss';
import img from '../../assets/photos/NGMPhoto.webp';

const SectionAboutNMG = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  console.log(isMobile);

  return (
    <Background backgroundcolor={colors.secondaryColor}>
      <Container>
        <Content ismobile={isMobile}>
          <GlobalImageContainer
            className="col-8 md:col-12"
            aria-label="Psicóloga segurando mão de paciente, reconfortando-o"
            textcolor={colors.backgroundBlack}
            img={img}
          >
            <h2 className="mt-8">NOVA MEDICINA GERMÂNICA</h2>
            <h2>O que é</h2>
          </GlobalImageContainer>
          <TextContainer className="col-4 md:col-12">
            <p className="p-3">
              É uma abordagem integrativa de saúde, que visa identificar a causa
              raiz das doenças e sintomas.
              <br />
              <br />
              A Nova Medicina Germânica entende que nosso corpo possui uma
              capacidade intrínseca de se autorregenerar. Portanto, a cura é
              sempre um processo que vem de dentro da pessoa e não de fatores
              externos.
              <br />
              <br />
              Nosso corpo é um sistema unificado e nossas emoções e nossa
              biologia são inseparáveis, sendo assim, o que acontece no campo
              emocional inevitavelmente vai afetar o campo biológico.
              <br />
              <br />
              A compreensão do ser humano de forma integral, compreende que é
              impossível tratar apenas o corpo.
              <br />
              <br />
              Dessa forma, o tratamento integra um elaborado trabalho no campo
              mental e emocional para ativar a resposta de cura biológica no
              corpo.
            </p>
          </TextContainer>
        </Content>
      </Container>
    </Background>
  );
};

export default SectionAboutNMG;
