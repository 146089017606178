import styled from 'styled-components';
import img from '../../assets/photos/home2.webp';
import colors from '../../styles/sass/_variables.scss';
import { FaWhatsapp } from 'react-icons/fa';

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: 100% 50%;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: ${colors.tertiaryTextColor};
    opacity: 0.4;
    z-index: 1;
  }

  @media (max-width: 768px) {
    background-position: 20% 50%;
  }

  @media (max-width: 560px) {
    background-position: 45% 50%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  width: 100%;
  min-width: 300px;
  max-height: 830px;

  margin-right: auto;
  margin-left: auto;

  justify-content: center;
  z-index: 3;

  @media (min-width: 768px) {
    max-width: 750px;
  }
  @media (min-width: 992px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 3;
`;
export const Height = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  z-index: 3;

  @media (max-width: 968px) {
    align-items: center;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  z-index: 3;
`;

export const Title = styled.h1`
  font-size: 3rem;
  width: 100%;
  max-width: 900px;
  margin: auto;
  padding-left: 10px;
  z-index: 3;

  color: ${colors.primaryTextColor};

  @media (max-width: 970px) {
    font-size: 35px;
  }

  @media (max-width: 460px) {
    font-size: 30px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 3rem;
  width: 100%;
  max-width: 900px;
  margin-top: auto;
  padding-left: 10px;
  z-index: 3;
  color: ${colors.primaryTextColor};

  @media (max-width: 970px) {
    font-size: 35px;
  }

  @media (max-width: 460px) {
    font-size: 30px;
  }
`;

export const Text = styled.p`
  font-size: 1.1rem;
  padding-bottom: 15px;
  padding-top: 5px;
  text-align: center;
  color: ${colors.primaryTextColor};
  z-index: 3;
  transition: transform 0.3s ease;
`;

export const ContactsContainer = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.8rem;
  gap: 20px;
  z-index: 3;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const WhatsLink = styled.a`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 200px;
  padding: 8px;
  gap: 10px;
  z-index: 3;

  font-size: 20px;

  text-decoration: none;
  color: ${colors.headerBackgroundColor};
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const TextWhats = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 3;
`;

export const Icon = styled(FaWhatsapp)`
  transition: transform 0.3s ease;
  z-index: 3;
  &:hover {
    transform: scale(1.1);
  }
`;
