import React from 'react';
import { Container, Content, StyledFooter } from './style';
import { animateScroll as scroll } from 'react-scroll';
import { Links } from '../../assets/globalStyles/styles';
import colors from '../../styles/sass/_variables.scss';
import Contact from '../SectionContacts/Contact';

const currentDate = new Date().getFullYear();

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Content className="flex-row">
          <Links
            to="home"
            onClick={() => {
              scroll.scrollToTop();
            }}
            className="mr-2"
          >
            Graciellen de Moura
          </Links>
          &copy; {currentDate}
        </Content>
        <Content>
          Desenvolvido por:
          <Contact
            text="Leonardo Pacheco"
            href="https://linktr.ee/LeonardoPacheco1995"
            color={colors.primaryColor}
            className="pb-0"
          />
        </Content>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
