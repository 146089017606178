import { Links } from '../../../assets/globalStyles/styles';
import { StyledNav } from './style';
import { animateScroll as scroll } from 'react-scroll';
import colors from '../../../styles/sass/_variables.scss';

export const Navmenu = () => {
  return (
    <StyledNav>
      <div>
        <Links
          to="home"
          smooth={true}
          duration={500}
          onClick={() => {
            scroll.scrollToTop();
          }}
          color={colors.primaryColor}
          className="letter-spacing-2"
        >
          Home
        </Links>
        <span>|</span>
        <Links
          to="sobre"
          smooth={true}
          duration={500}
          color={colors.primaryColor}
          className="letter-spacing-2"
        >
          Quem sou eu
        </Links>
        <span>|</span>
        <Links
          to="serviços"
          smooth={true}
          duration={500}
          color={colors.primaryColor}
          className="letter-spacing-2"
        >
          Serviços
        </Links>
        <span>|</span>
        <Links
          to="contatos"
          smooth={true}
          duration={500}
          color={colors.primaryColor}
          className="letter-spacing-2"
        >
          Contatos
        </Links>
      </div>
    </StyledNav>
  );
};
