import { Chip, Divider } from '@mui/material';
import styled from 'styled-components';
import colors from '../../styles/sass/_variables.scss';
import { Link } from 'react-scroll';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.backgroundcolor};
`;
export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.ismobile ? 'column' : 'row')};
  align-items: center;
`;

export const GlobalImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 50%;

  background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.3)
    ),
    url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  h2 {
    color: ${(props) => props.textcolor};
    font-size: 36px;
    text-align: left;
    padding-left: 40px;
    max-width: 350px;
    font-weight: bold;
    font-family: 'Cormorant Garamond', 'serif';
  }

  h2 + h2 {
    margin-top: 15px;
    font-weight: bold;
    font-family: 'Cormorant Garamond', 'serif';
    font-style: italic;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-position: 50%;
  }
`;

export const DividerContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-top: 30px;
`;

export const CustomDivider = styled(Divider)`
  &::before,
  &::after {
    border-top: 1px solid ${colors.primaryColor} !important;
  }

  border-color: ${colors.primaryColor} !important;
`;

export const Links = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.color};

  cursor: pointer;
  transition: color 0.2s;
  transition: transform 0.3s ease;

  &:hover,
  &:active,
  &:focus {
    transform: scale(1.1);
    color: ${colors.headerHoverText};
  }
`;

export const StyledChip = styled(Chip)`
  font-size: 22px !important;
  font-weight: normal !important;
  color: ${(props) => props.textcolor} !important;
  padding: 5px 10px !important;

  @media (max-width: 960px) {
    font-size: 16px !important;
  }
`;
