import styled from 'styled-components';
export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SectionH = styled.section`
  width: 100%;
  height: 100vh;
  max-height: 830px;
  min-height: 600px;
`;
export const Section = styled.section`
  width: 100%;
  height: 70vh;
  max-height: 700px;
  min-height: 600px;

  @media (max-width: 992px) {
    min-height: 1040px;
  }
`;

export const SectionService = styled.section`
  width: 100%;
  height: 70vh;
  max-height: 700px;
  min-height: 600px;
`;

export const SectionContact = styled.section`
  height: 180px;
  width: 100%;

  @media (max-width: 768px) {
    height: fit-content;
  }
`;

export const SectionFooter = styled.section`
  height: 70px;
  width: 100%;

  @media (max-width: 768px) {
    height: fit-content;
  }
`;
