import React from 'react';
import { BrandDiv, BrandName, Container, StyledHeader } from './style';
import { useMediaQuery } from 'react-responsive';
import { NavMobileMenu } from './NavMobileMenu';
import { Navmenu } from './Navmenu';

const Header = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  return (
    <StyledHeader>
      <Container>
        {isMobile && <NavMobileMenu />}
        <BrandDiv>
          <BrandName>
            <h2>Graciellen de Moura</h2>
          </BrandName>
        </BrandDiv>
        {!isMobile && <Navmenu />}
      </Container>
    </StyledHeader>
  );
};

export default Header;
