import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  min-width: 365px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    max-width: 750px;
  }
  @media (min-width: 966px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
