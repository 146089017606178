import styled, { keyframes } from 'styled-components';
import colors from '../../styles/sass/_variables.scss';

const fadeIn = keyframes`
0% {
  opacity: 0;
  transform: translateX(-50px);
}
100% {
  opacity: 1;
  transform: translateX(0);
}
`;

export const StyledHeader = styled.header`
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  position: sticky;
  align-items: center;
  z-index: 5;

  background-color: ${colors.headerBackgroundColor};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  min-width: 365px;
  max-width: 1200px;
  align-items: center;
`;
export const BrandDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-style: italic;

  @media (max-width: 960px) {
    justify-content: center;
  }
`;
export const BrandName = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;

  color: ${colors.primaryColor};

  @media (max-width: 768px) {
    margin-left: 0;
  }

  h2 {
    font-size: 32px;
    @media (max-width: 468px) {
      font-size: 26px;
    }
    animation: ${fadeIn} 2s ease-in-out;
  }
`;
