import React from 'react';
import { Container, Content } from './style';
import { FaInstagram, FaPhone, FaWhatsapp } from 'react-icons/fa';
import colors from '../../styles/sass/_variables.scss';

import Contact from './Contact';
import {
  Background,
  CustomDivider,
  DividerContainer,
  StyledChip,
} from '../../assets/globalStyles/styles';

export const SectionContacts = ({ id }) => {
  const handleClick = () => {
    window.open('https://wa.me/5548992098484', '_blank');
  };

  return (
    <Background backgroundcolor={colors.headerBackgroundColor}>
      <Container id={id}>
        <DividerContainer className="w-10">
          <CustomDivider className="font-normal" flexItem textAlign="center">
            <StyledChip
              label="Entre em contato!"
              variant="outlined"
              icon={<FaWhatsapp color={`${colors.secondaryColor}`} />}
              onClick={handleClick}
              textcolor={`${colors.secondaryColor}`}
            />
          </CustomDivider>
        </DividerContainer>
        <Content className="p-5">
          <Contact
            Icon={FaInstagram}
            text="@psicologagraciellen"
            href="https://instagram.com/psicologagraciellen"
            color={`${colors.primaryColor}`}
          />
          <Contact
            Icon={FaPhone}
            text="(48) 99209-8484"
            href="tel:48992098484"
            color={`${colors.primaryColor}`}
          />
        </Content>
        <DividerContainer className="mt-0">
          <CustomDivider />
        </DividerContainer>
      </Container>
    </Background>
  );
};

export default SectionContacts;
