import styled from 'styled-components';
import colors from '../../styles/sass/_variables.scss';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  max-width: 480px;
  min-width: 300px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: 992px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  height: 100%;
  padding: 0 1rem;
  justify-content: center;
  overflow: hidden;

  p {
    color: ${colors.backgroundBlack};
    font-size: 22px;
    text-align: justify;
  }

  @media (max-width: 768px) {
    width: 100%;

    p {
      font-size: 16px;
    }
  }
`;
