import styled from 'styled-components';
import colors from '../../../styles/sass/_variables.scss';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: ${(props) => props.color || colors.backgroundBlack};

  a {
    text-align: center;
    text-decoration: none;
    color: inherit;
  }

  .icon {
    transition: color 0.3s ease;
    color: inherit;
  }

  &:hover .icon {
    color: ${colors.headerHoverText};
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
`;
