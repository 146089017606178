import React from 'react';
import { Container, Content, Description, Image, Title } from './style';
import img from '../../assets/photos/aboutMePhoto1.webp';
import { useMediaQuery } from 'react-responsive';
import { Divider } from '@mui/material';
import colors from '../../styles/sass/_variables.scss';
import { FaWhatsapp } from 'react-icons/fa';
import { StyledChip } from '../../assets/globalStyles/styles';

const SectionAboutMe = ({ id }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const handleClick = () => {
    window.open('https://wa.me/5548992098484', '_blank');
  };

  return (
    <Container id={id}>
      <Content>
        {isMobile && (
          <Image
            src={img}
            alt="Psicóloga com um leve sorriso"
            title="Quem sou eu"
          />
        )}
        <Title>QUEM SOU EU</Title>
        <div className="w-full flex flex-column justify-content-center lg:mt-5">
          <Description>
            Me chamo Graciellen de Moura, sou psicóloga desde 2013 e me
            especializei em Psicologia Clínica e Nova Medicina Germânica.
            <br />
            <br />
            Estou comprometida a oferecer atendimentos personalizados e
            acolhedores, cujo objetivo visa tratar a causa raiz de problemas
            físicos, mentais e emocionais.
            <br />
            <br />
            Meu proposito é encontrar a causa do seu problema de saúde e
            oferecer soluções para que você conquiste a saúde plena.
            <br />
            <br />
            Estou á disposição para te ajudar, te orientar e te apoiar nessa
            jornada rumo a uma vida melhor.
          </Description>
        </div>
        <Divider className="mt-4 font-bold" flexItem>
          <StyledChip
            label="Como posso te ajudar?"
            icon={<FaWhatsapp />}
            variant="outlined"
            onClick={handleClick}
            textcolor={colors.backgroundBlack}
          />
        </Divider>
      </Content>
      {!isMobile && (
        <div className="col-4 relative flex flex-column align-items-center justify-content-center">
          <Image src={img} alt="Psicóloga com um leve sorriso" />
        </div>
      )}
    </Container>
  );
};

export default SectionAboutMe;
