import styled from 'styled-components';
import colors from '../../styles/sass/_variables.scss';

export const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  background-color: ${colors.headerBackgroundColor};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-width: 480px;
  min-width: 365px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    max-width: 750px;
  }
  @media (min-width: 966px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  div {
    color: ${colors.primaryColor};
    font-weight: bold;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 7px;

  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;
