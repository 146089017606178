import React from 'react';
import { Container, TextContainer } from './style';
import { useMediaQuery } from 'react-responsive';
import colors from '../../styles/sass/_variables.scss';
import {
  Background,
  Content,
  GlobalImageContainer,
} from '../../assets/globalStyles/styles';
import img from '../../assets/photos/homePhoto.webp';

const SectionHowNMG = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <Background backgroundcolor={colors.tertiaryTextColor}>
      <Container>
        <Content ismobile={isMobile}>
          <GlobalImageContainer
            className="col-8 md:col-12"
            aria-label="Mão com a luz do sol batendo, aberta, sugerindo ajuda"
            img={img}
            textcolor={colors.backgroundBlack}
          >
            <h2 className="mt-8">NOVA MEDICINA GERMÂNICA</h2>
            <h2>Como funciona</h2>
          </GlobalImageContainer>
          <TextContainer className="col-4 md:col-12">
            <p className="p-3">
              O tratamento inicia-se com a realização de uma investigação
              aprofundada para fazer um levantamento de todas as informações que
              são pertinentes ao problema de saúde que se quer tratar.
              <br />
              <br />
              É feito um rastreamento, para identificar a causa raiz do
              problema.
              <br />
              <br />
              Identificada a causa do sintoma/doença, são propostas estratégias
              resolutivas que são elaboradas de forma individualizada
              especialmente para cada pessoa.
              <br />
              <br />
              São realizadas intervenções terapêuticas que impulsionam a pessoa
              para o caminho da autocura e da resolução do problema.
            </p>
          </TextContainer>
        </Content>
      </Container>
    </Background>
  );
};

export default SectionHowNMG;
