import styled from 'styled-components';
import colors from '../../../styles/sass/_variables.scss';
export const HamburgerButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  margin-right: 1rem;
  margin-left: 1rem;

  &:focus {
    outline: none;
  }

  .icon {
    color: ${colors.primaryColor};
  }
`;

export const HamburgerMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HamburgerSpan = styled.span`
  color: ${colors.primaryColor};
  font-size: 1rem;
`;
export const NavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  li {
    margin: 10px 0;

    a {
      text-decoration: none;
      font-size: 1.5rem;

      span {
        position: relative;
        display: inline-block;

        &:active,
        &:focus {
          color: ${colors.primaryBackgroundColor};
        }
      }
    }
  }
`;
