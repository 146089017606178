import React from 'react';
import { Container } from './style';

export const Contact = ({ Icon, ...props }) => {
  const { text, href, color } = props;

  return (
    <Container color={color}>
      <a href={href} target="_blank" rel="noreferrer">
        {Icon && <Icon size={26} className="icon" />}
        {text && <p className="mt-2 icon">{text}</p>}
      </a>
    </Container>
  );
};

export default Contact;
