import React from 'react';
import { Container, Content, ImageContainer } from './style';
import {
  Background,
  CustomDivider,
  DividerContainer,
} from '../../assets/globalStyles/styles';
import colors from '../../styles/sass/_variables.scss';
export const SectionServices = () => {
  return (
    <Background backgroundcolor={colors.primaryTextColor}>
      <Container>
        <ImageContainer aria-label="Objeto circular, tocando a superfície, simulando ondas">
          <Content>
            <h2 className="col-2 mb-auto">SERVIÇOS</h2>
            <h3 className="col-2">Tratamento com Nova Medicina Germânica</h3>
            <p className="text-center">
              Tratamento indicado para problemas de saúde físicos dividido em
              sessões de acordo com a necessidade de cada caso.
            </p>
            <DividerContainer>
              <CustomDivider flexItem />
            </DividerContainer>
            <h3 className="col-2 mt-5">Sessões de Psicoterapia</h3>
            <p className="text-center mb-auto">
              Sessões individuais de psicoterapia indicado para tratamento de
              questões de saúde mental e emocional.
            </p>
            <p>Atendimento on-line</p>
          </Content>
        </ImageContainer>
      </Container>
    </Background>
  );
};

export default SectionServices;
