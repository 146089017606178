import styled from 'styled-components';
import colors from '../../styles/sass/_variables.scss';
import img from '../../assets/photos/servicesBackground.webp';
import { GlobalImageContainer } from '../../assets/globalStyles/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 480px;
  min-width: 300px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: 750px;
  }
  @media (min-width: 992px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export const ImageContainer = styled(GlobalImageContainer)`
  background-image: linear-gradient(
      rgba(52, 69, 61, 0.9),
      rgba(52, 69, 61, 0.9)
    ),
    url(${img});

  background-position: 50%;

  h2 {
    padding-left: 0;
  }
`;
export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.primaryColor};
  z-index: 2;

  h2 {
    font-size: 36px;
    width: fit-content;
    margin-top: 0;
    font-weight: normal;
    font-family: 'Cormorant Garamond', 'serif';
  }
  h3 {
    font-size: 26px;
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    width: fit-content;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;
