import React from 'react';
import {
  HamburgerButton,
  HamburgerMenu,
  HamburgerSpan,
  NavList,
} from './style';
import { Link } from 'react-scroll';
import { animateScroll as scroll } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa';

export const NavMobileMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <HamburgerButton onClick={handleToggle}>
        {isOpen ? (
          <FaTimes size={24} className="icon" />
        ) : (
          <FaBars size={24} className="icon" />
        )}
      </HamburgerButton>
      {isOpen && (
        <HamburgerMenu>
          <NavList>
            <li>
              <Link
                smooth={true}
                duration={500}
                onClick={() => {
                  handleToggle();
                  scroll.scrollToTop();
                }}
              >
                <HamburgerSpan>Home</HamburgerSpan>
              </Link>
            </li>
            <li>
              <Link
                to="sobre"
                smooth={true}
                duration={500}
                onClick={handleToggle}
              >
                <HamburgerSpan>Quem sou eu</HamburgerSpan>
              </Link>
            </li>
            <li>
              <Link
                to="serviços"
                smooth={true}
                duration={500}
                onClick={handleToggle}
              >
                <HamburgerSpan>Serviços</HamburgerSpan>
              </Link>
            </li>
            <li>
              <Link
                to="contatos"
                smooth={true}
                duration={500}
                onClick={handleToggle}
              >
                <HamburgerSpan>Contato</HamburgerSpan>
              </Link>
            </li>
          </NavList>
        </HamburgerMenu>
      )}
    </>
  );
};
